import axios from 'axios';
import { API_PATH } from '../../constants';
import * as Actions from './actionTypes';

export const fetchUsers = (token: string, page_number: number) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                url: `${API_PATH}/admin/users`,
                headers: {
                    Authorization: token
                },
                data: { page_number }
            };
            const users = (await axios(config as any))?.data?.data;
            dispatch({
                type: Actions.SET_USER_DATA,
                payload: users
            });
        } catch (e) {
            throw e;
        }
    };
};

export const fetchBizServices = (token: string, page_number: number) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                url: `${API_PATH}/admin/biz-services`,
                headers: {
                    Authorization: token
                },
                data: { page_number }
            };
            const post = (await axios(config as any))?.data?.data;
            dispatch({
                type: Actions.SET_BIZ_SERVICES_DATA,
                payload: post
            });
        } catch (e) {
            throw e;
        }
    };
};

export const fetchRealEstates = (token: string, page_number: number) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                url: `${API_PATH}/admin/real-estate`,
                headers: {
                    Authorization: token
                },
                data: { page_number }
            };
            const post = (await axios(config as any))?.data?.data;
            dispatch({
                type: Actions.SET_REAL_ESTATE_DATA,
                payload: post
            });
        } catch (e) {
            throw e;
        }
    };
};

export const fetchBuySell = (token: string, page_number: number) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                url: `${API_PATH}/admin/buy-sell`,
                headers: {
                    Authorization: token
                },
                data: { page_number }
            };
            const post = (await axios(config as any))?.data?.data;
            dispatch({
                type: Actions.SET_BUY_SELL_DATA,
                payload: post
            });
        } catch (e) {
            throw e;
        }
    };
};

export const fetchOffers = (token: string, page_number: number) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                url: `${API_PATH}/admin/offers`,
                headers: {
                    Authorization: token
                },
                data: { page_number }
            };
            const post = (await axios(config as any))?.data?.data;
            dispatch({
                type: Actions.SET_OFFERS_DATA,
                payload: post
            });
        } catch (e) {
            throw e;
        }
    };
};

export const fetchStories = (token: string, page_number: number) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                url: `${API_PATH}/admin/stories`,
                headers: {
                    Authorization: token
                },
                data: { page_number }
            };
            const post = (await axios(config as any))?.data?.data;
            dispatch({
                type: Actions.SET_STORIES_DATA,
                payload: post
            });
        } catch (e) {
            throw e;
        }
    };
};

export const fetchUserProfile = (token: string, user_id: number) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'get',
                url: `${API_PATH}/admin/user-profile?user_id=${user_id}`,
                headers: {
                    Authorization: token
                }
            };
            const data = (await axios(config as any))?.data?.data;
            return data;
        } catch (e) {
            throw e;
        }
    };
};

export const fetchOneClassifieds = (token: string, post_id: number) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'get',
                url: `${API_PATH}/biz-service/${post_id}`,
                headers: {}
            };
            const data = (await axios(config as any))?.data?.data;
            return data;
        } catch (e) {
            throw e;
        }
    };
};

export const fetchOneRealEstate = (token: string, post_id: number) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'get',
                url: `${API_PATH}/real-estate/${post_id}`,
                headers: {}
            };
            const data = (await axios(config as any))?.data?.data;
            return data;
        } catch (e) {
            throw e;
        }
    };
};

export const fetchOneSellData = (token: string, post_id: number) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'get',
                url: `${API_PATH}/buy-sell/${post_id}`,
                headers: {}
            };
            const data = (await axios(config as any))?.data?.data;
            return data;
        } catch (e) {
            throw e;
        }
    };
};

export const fetchOneOfferData = (token: string, post_id: number) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'get',
                url: `${API_PATH}/offer/${post_id}`,
                headers: {}
            };
            const data = (await axios(config as any))?.data?.data;
            return data;
        } catch (e) {
            throw e;
        }
    };
};

export const fetchOneStoryData = (token: string, story_id: number) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'get',
                url: `${API_PATH}/admin/get-one-story?story_id=${story_id}`,
                headers: {
                    Authorization: token
                }
            };
            const data = (await axios(config as any))?.data?.data;
            return data;
        } catch (e) {
            throw e;
        }
    };
};
export const fetchOneProductsServicesData = (
    token: string,
    service_id: number
) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'get',
                url: `${API_PATH}/admin/biz-service/${service_id}`,
                headers: {
                    Authorization: token
                }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};
export const fetchOneJobsData = (token: string, service_id: number) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'get',
                url: `${API_PATH}/admin/job-service/${service_id}`,
                headers: {
                    Authorization: token
                }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};
export const updateUserProfile = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/update/user`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return response;
        } catch (e) {
            throw e;
        }
    };
};

export const uploadImage = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/upload/profile-picture`,
                headers: { Authorization: token }
            };
            await axios(config as any);
        } catch (e) {
            throw e;
        }
    };
};

export const deleteUser = (token: string, user_id: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data: { user_id },
                url: `${API_PATH}/admin/delete/user`,
                headers: { Authorization: token }
            };
            await axios(config as any);
        } catch (e) {
            throw e;
        }
    };
};

export const deleteService = (token: string, service_id: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data: { service_id },
                url: `${API_PATH}/admin/delete/biz-services`,
                headers: { Authorization: token }
            };
            await axios(config as any);
        } catch (e) {
            throw e;
        }
    };
};

export const deleteRealEstate = (token: string, property_id: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data: { property_id },
                url: `${API_PATH}/admin/delete/real-estate`,
                headers: { Authorization: token }
            };
            await axios(config as any);
        } catch (e) {
            throw e;
        }
    };
};

export const deleteBuySell = (token: string, buy_sell_id: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data: { buy_sell_id },
                url: `${API_PATH}/admin/delete/buy-sell`,
                headers: { Authorization: token }
            };
            await axios(config as any);
        } catch (e) {
            throw e;
        }
    };
};

export const deleteOffer = (token: string, offer_id: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data: { offer_id },
                url: `${API_PATH}/admin/delete/offers`,
                headers: { Authorization: token }
            };
            await axios(config as any);
        } catch (e) {
            throw e;
        }
    };
};

export const deleteStories = (token: string, story_id: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data: { story_id },
                url: `${API_PATH}/admin/delete/stories`,
                headers: { Authorization: token }
            };
            await axios(config as any);
        } catch (e) {
            throw e;
        }
    };
};

export const verifyUser = (token: string, user_id: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data: { user_id },
                url: `${API_PATH}/admin/verify/user`,
                headers: { Authorization: token }
            };
            await axios(config as any);
        } catch (e) {
            throw e;
        }
    };
};

export const verifyService = (token: string, service_id: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data: { service_id },
                url: `${API_PATH}/admin/verify/biz-services`,
                headers: { Authorization: token }
            };
            await axios(config as any);
        } catch (e) {
            throw e;
        }
    };
};

export const verifyRealEstate = (token: string, property_id: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data: { property_id },
                url: `${API_PATH}/admin/verify/real-estate`,
                headers: { Authorization: token }
            };
            await axios(config as any);
        } catch (e) {
            throw e;
        }
    };
};

export const updateClassified = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/update/biz-services`,
                headers: { Authorization: token }
            };
            await axios(config as any);
        } catch (e) {
            throw e;
        }
    };
};

export const updateBuySell = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/update/buy-sell`,
                headers: { Authorization: token }
            };
            await axios(config as any);
        } catch (e) {
            throw e;
        }
    };
};

export const updateOffer = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/update/offers`,
                headers: { Authorization: token }
            };
            await axios(config as any);
        } catch (e) {
            throw e;
        }
    };
};

export const updateStory = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/update/stories`,
                headers: { Authorization: token }
            };
            await axios(config as any);
        } catch (e) {
            throw e;
        }
    };
};
export const UpdateProductsServices = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/update/biz-services`,
                headers: { Authorization: token }
            };
            const payload = (await axios(config as any))?.data?.data;
            return { type: 'success', response: payload };
        } catch (e) {
            return { type: 'failed', response: e?.response };
        }
    };
};

export const UpdateJobsServices = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/update/job-services`,
                headers: { Authorization: token }
            };
            const payload = (await axios(config as any))?.data?.data;
            return { type: 'success', response: payload };
        } catch (e) {
            return { type: 'failed', response: e?.response };
        }
    };
};

export const updateRealEstate = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/update/real-estate`,
                headers: { Authorization: token }
            };
            await axios(config as any);
        } catch (e) {
            throw e;
        }
    };
};

export const uploadImages = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/upload/post-pictures`,
                headers: { Authorization: token }
            };
            const payload = (await axios(config as any))?.data?.data;
            return { type: 'success', response: payload };
        } catch (e) {
            throw e;
        }
    };
};

export const setSelectedTab = (index: number) => ({
    type: Actions.CHANGE_SELECTED_MENU,
    payload: index
});
