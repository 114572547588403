import * as ACTIONS from './actionTypes';

const initialValues = {
    tableHeader: [
        { id: 'actions', label: '' },
        { id: 'createdAt', label: 'Listing Date' },
        { id: 'expiry_date', label: 'Expiry Date' },
        { id: 'status', label: 'Listing Status' },
        { id: 'type', label: 'Listing Type' },
        { id: 'is_product', label: 'Job Type' },
        { id: 'name', label: 'Title' },
        { id: 'discount', label: 'Discount' },
        { id: 'owner_name', label: 'Name' },
        { id: 'owner_email', label: 'Email' },
        { id: 'bizservice_tags', label: 'Additional Tags' }
    ],
    allProductsServicesList: {
        current_page: 0,
        page_size: 0,
        total_pages: 0,
        posts: [],
        count: 0
    }
};

const JobsReducer = (state = initialValues, action: any) => {
    switch (action.type) {
        case ACTIONS.SET_ALL_PRODUCT_SERVICES_DATA:
            return {
                ...state,
                allProductsServicesList: action?.payload
            };
        default:
            return state;
    }
};

export default JobsReducer;
