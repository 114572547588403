import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { ROWS_PER_PAGE, Routes } from '../../constants';
import { CommonDispatcher } from '../../redux/Common/action';
import { getQuery } from '../../utils/common';
import { getSelectedTab } from '../Common/commonUtils';
import AllProductsServices from './AllProductsServices';
import { GetAllProductsServicesList } from '../../redux/JobsServices/actions';

const Jobs = ({ GetAllProductsServicesList, CommonDispatcher }) => {
    const history = useHistory();
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [value, setValue] = React.useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isTabLoading, setIsTabLoading] = useState(false);
    const [isFilterApplied, setIsFilterApplied] = useState(false);

    const query = getQuery(history);
    const type = query.get('type') || '';
    const keyword = query.get('keyword');
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const filterParam = searchParams.get('filter');
    let decodedFilters;
    if (filterParam) {
        decodedFilters = JSON.parse(decodeURIComponent(filterParam));
    }

    const handleTabLoading = (data) => {
        setIsTabLoading(data);
    };
    useEffect(() => {
        if (keyword || type || type === '' || decodedFilters) {
            setIsFilterApplied(true);
            getData({
                keyword,
                type,
                tab: getSelectedTab(history?.location?.pathname)
            });
        }
    }, [keyword, type, filterParam]);

    const getAllTabsData = (data) => {
        if (!data?.tab) {
            getData({ data }, false);
        }
        /*  [FirmsTab.TYPE_ACTIVE, FirmsTab.TYPE_IN_ACTIVE].forEach((tab) => {
            getData({ page: 1, tab }, false);
        }); */
    };
    const getSelectedTabData = (data) => {
        getData({
            data,
            keyword,
            tab: getSelectedTab(history?.location?.pathname)
        });
    };

    const getData = (data, isLoading = true, setReduxListing = true) => {
        data.size = ROWS_PER_PAGE;
        if (filterParam) {
            data.filter = decodedFilters;
        }
        setIsLoading(isLoading);
        GetAllProductsServicesList(token, data, setReduxListing).then(() => {
            setIsLoading(false);
            setTab();
        });
        setIsTabLoading(false);
    };

    const setTab = () => {
        switch (history.location?.pathname) {
            default:
            case Routes.jobsServices.index:
                setValue(0);
                break;
        }
    };

    const handleChange = (newValue) => {
        setValue(newValue);
        let pathname;
        switch (newValue) {
            case 0:
            default:
                pathname = Routes.jobsServices.index;
                break;
        }
        setIsTabLoading(true);
        history.push(pathname);
    };

    return isLoading ? (
        <div className="mainLoader">
            <div className="loader" />
        </div>
    ) : (
        <>
            {/* <Tabs
                value={value}
                onChange={(e, v) => handleChange(v)}
                variant="scrollable"
                indicatorColor="primary"
                textColor="primary"
                scrollButtons="on"
                classes={{
                    flexContainer: !isSmallScreen
                        ? 'justify-content-center'
                        : ''
                }}
            >
                <Tab
                    label={`All`}
                    className="text-inherit"
                    disabled={isTabLoading}
                    disableRipple={true}
                    disableFocusRipple={true}
                />
            </Tabs> */}
            <>
                <Route
                    path={Routes.jobsServices.index}
                    exact
                    render={() => (
                        <AllProductsServices
                            sectionBaseUrl={Routes.jobsServices.index}
                            loading={isLoading}
                            isTabLoading={isTabLoading}
                            isFilterApplied={isFilterApplied}
                            getAllTabsData={getAllTabsData}
                            getSelectedTabData={getSelectedTabData}
                            handleTabLoading={handleTabLoading}
                        />
                    )}
                />
            </>
        </>
    );
};
export default connect(null, { GetAllProductsServicesList, CommonDispatcher })(
    Jobs
);
