import { Routes } from '../constants';

export const ENV = process.env.REACT_APP_ENV;
export const TEST_DOMAIN = process.env.REACT_APP_DOMAIN;
export const LIVE_DOMAIN = process.env.REACT_APP_DOMAIN;
export const LIVE_URL = process.env.REACT_LIVE_URL;
export const WEBSITE_PLACEHOLDER = `e.g. ${LIVE_URL}`;
export const MAX_DOC_UPLOAD_SIZE = 5; // 5MB

export const TRACK_RECORDS_TYPE_LIST = [
    { title: 'Fund Track Record', value: 'FUND_TRACK_RECORD', disabled: false },
    { title: 'Balance Sheet', value: 'BALANCE_SHEET', disabled: false },
    { title: 'Other', value: 'OTHER', disabled: false }
];
export const DOCUMENT_MIME_TYPES = [
    'image/png',
    'image/jpg',
    'image/jpeg',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
    'application/vnd.ms-word.document.macroEnabled.12',
    'application/vnd.ms-word.template.macroEnabled.12',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
    'application/vnd.ms-excel.sheet.macroEnabled.12',
    'application/vnd.ms-excel.template.macroEnabled.12',
    'application/vnd.ms-excel.addin.macroEnabled.12',
    'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.presentationml.template',
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
    'application/vnd.ms-powerpoint.addin.macroEnabled.12',
    'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
    'application/vnd.ms-powerpoint.template.macroEnabled.12',
    'application/vnd.ms-powerpoint.slideshow.macroEnabled.12',
    'application/x-iwork-pages-sffpages',
    'application/pdf',
    'text/plain',
    'text/csv'
];

export const APPROVED = 'APPROVED';
export const PINEGRAY = 'PINEGRAY';
export const ACTIVE = 'ACTIVE';
export const AWAITING_APPROVAL = 'AWAITING_APPROVAL';
export const SUSPENDED = 'SUSPENDED';
export const DECLINED = 'DECLINED';
export const GOOGLE_MAP_API_KEY =
    process.env.REACT_APP_GOOGLE_MAP_API_KEY ||
    'AIzaSyCaR0Rx8yJtiFKfLj7IW2awoq07peseDHM';

export const ACCOUNT_TYPE = {
    ALL: 'ALL',
    INVESTOR: 'INVESTOR',
    PRIVATE_INVESTOR: 'PRIVATE_INVESTOR',
    STARTUP: 'STARTUP',
    ECOSYSTEM: 'ECOSYSTEM',
    PUBLIC: 'PUBLIC'
};
export const UsersTypeList = [
    { title: 'All', value: ACCOUNT_TYPE.ALL, disabled: false },
    {
        title: 'Private Investor',
        value: ACCOUNT_TYPE.PRIVATE_INVESTOR,
        disabled: false
    },
    { title: 'Startup', value: 'STARTUP', disabled: false },
    { title: 'Investor', value: 'INVESTOR', disabled: false },
    { title: 'Ecosystem', value: ACCOUNT_TYPE.ECOSYSTEM, disabled: false }
];
export const FirmsTypeList = [
    { title: 'All', value: ACCOUNT_TYPE.ALL, disabled: false },
    { title: 'Startup', value: 'STARTUP', disabled: false },
    { title: 'Investor', value: 'INVESTOR', disabled: false },
    { title: 'Ecosystem', value: ACCOUNT_TYPE.ECOSYSTEM, disabled: false }
];

export const DrawerMenuItems = [
    {
        route: Routes.home,
        label: 'Home',
        key: '',
        icon: () => {}
    },
    {
        route: Routes.users.users,
        label: 'Users',
        key: 'admin_user_tab',
        subMenu: [],
        icon: () => {}
    },

    {
        route: Routes.deals.awaitingApproval,
        label: 'Deals',
        key: 'admin_startup_deals_tab',
        subMenu: [],
        icon: () => {}
    },
    {
        route: Routes.fund_deals.awaitingApproval,
        label: 'Fund Deals',
        key: 'admin_fund_deals_tab',
        subMenu: [],
        icon: () => {}
    },
    {
        route: Routes.firms.firms,
        label: 'Firms',
        key: 'admin_fund_firms_tab',
        subMenu: [],
        icon: () => {}
    },
    {
        route: Routes.productsServices.index,
        label: 'Products / Services',
        key: 'admin_bizservice_tab',
        subMenu: [],
        icon: () => {}
    },
    {
        route: Routes.jobsServices.index,
        label: 'Jobs',
        key: 'admin_jobservice_tab',
        subMenu: [],
        icon: () => {}
    },
    {
        route: Routes.kycProcess,
        label: 'KYC Process',
        key: '',
        subMenu: [],
        icon: () => {}
    },
    {
        route: Routes.events.events,
        label: 'Events',
        key: 'admin_events_tab',
        subMenu: [],
        icon: () => {}
    },
    {
        route: Routes.stories,
        label: 'Stories',
        key: 'admin_stories_tab',
        subMenu: [],
        icon: () => {}
    },
    {
        route: Routes.referral.email,
        label: 'Referral',
        key: 'admin_referal_tab',
        subMenu: [],
        icon: () => {}
    },
    {
        route: Routes.feedback,
        label: 'Feedback',
        key: 'admin_feedback_tab',
        subMenu: [],
        icon: () => {}
    },
    {
        route: Routes.metadata.metadata,
        label: 'MetaData',
        key: 'admin_metadata_tab',
        isActive: true,
        subMenu: [],
        icon: () => {}
    },
    {
        route: Routes.category,
        label: 'Category',
        key: 'admin_category_tab',
        isActive: true,
        subMenu: [],
        icon: () => {}
    },
    {
        route: Routes.clientConfig.index,
        label: 'Client Config',
        key: 'admin_client_configuration_tab',
        subMenu: [],
        icon: () => {}
    }
];
export const PHONE_PREFERRED_COUNTRIES = [
    'gb',
    'us',
    'de',
    'fr',
    'nl',
    'in',
    'ch',
    'es',
    'se',
    'ca',
    'ie',
    'it',
    'dk',
    'fi',
    'lu',
    'pt',
    'id',
    'au',
    'be'
];

export const CDN_IMAGE_LINK =
    ENV === 'DEVELOPMENT'
        ? process.env.REACT_APP_STAGING_IMAGE_CDN
        : process.env.REACT_APP_LIVE_IMAGE_CDN;
export const SAMPLE_FILE_URL = `${CDN_IMAGE_LINK}/firms_import.xlsx`;
