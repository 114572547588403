import { Chip, Table, useMediaQuery, useTheme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { FileCopy } from '@material-ui/icons';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { connect, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Routes, ROWS_PER_PAGE } from '../../../constants';
import { CommonDispatcher, OpenModal } from '../../../redux/Common/action';
import { SHOW_SNACKBAR } from '../../../redux/Common/actionTypes';
import {
    OPEN_ALERT_MODAL,
    OPEN_EXTEND_SUBSCRIPTIONS_MODAL,
    TOGGLE_CHANGE_OWNERSHIP_MODAL
} from '../../../redux/ModalReducer/actionTypes';
import {
    DeleteProductsServices,
    GetAllProductsServicesList
} from '../../../redux/JobsServices/actions';
import { RootState } from '../../../redux/store';
import { copyToClipboard, getQuery, goToListing } from '../../../utils/common';
import { FirmsTypeList } from '../../../utils/constants';
import { CommonTableStyle } from '../../Common/CommonTableStyle';
import TableHeaderFilter from '../../Common/TableHeaderFilter';
import { CommonPaginationAction } from '../../Users/Common/CommonPaginationAction';
import { UpdateJobsServices } from '../../../redux/Dashboard/actions';

const options = [
    { title: 'Edit', value: 'edit' },
    { title: 'Change ownership', value: 'changeOwnership' },
    { title: 'Expiry date', value: 'expiryDate' },
    { title: 'Delete', value: 'delete' }
];

const AllProductsServices = ({
    sectionBaseUrl,
    CommonDispatcher,
    GetAllProductsServicesList,
    DeleteProductsServices,
    loading,
    isMainFilterApplied,
    getAllTabsData,
    OpenModal,
    getSelectedTabData,
    handleTabLoading,
    isTabLoading,
    UpdateJobsServices
}: any) => {
    const classes = CommonTableStyle();
    const [isLoading, setIsLoading] = useState(false);
    const [isFilterApplied, setIsFilterApplied] = useState(isMainFilterApplied);

    const [selectedUser, setSelectedUser] = useState<any>({});
    const tableHeader = useSelector(
        (store: RootState) => store?.jobsServices.tableHeader || []
    );
    const listingsData = useSelector(
        (store: RootState) => store?.jobsServices.allProductsServicesList
    );
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const history = useHistory();
    const query = getQuery(history);
    const type = query.get('type');
    const keyword = query.get('keyword');
    const [page, setPage] = useState(0);
    const count = listingsData?.count;
    const total_pages = listingsData?.total_pages;
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const filterParam = searchParams.get('filter');
    let decodedFilters;
    if (filterParam) {
        decodedFilters = JSON.parse(decodeURIComponent(filterParam));
    }
    const categories =
        useSelector(
            (store: RootState) => store?.commonAppData.categories?.services
        ) || [];
    const theme = useTheme();
    const isTabletScreen = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        if (
            !isFilterApplied &&
            (keyword || type || typeof page !== 'undefined')
        ) {
            getData({
                keyword,
                type,
                filter: {},
                page_number: page + 1
            });
        }
        // eslint-disable-next-line
    }, [keyword, type, page]);

    const handleClick = (event: React.MouseEvent<HTMLElement>, user) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setSelectedUser(user);
    };

    const handleClose = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    const getData = (data) => {
        data.size = ROWS_PER_PAGE;
        setIsLoading(true);
        if (filterParam) {
            data.filter = decodedFilters;
        }
        GetAllProductsServicesList(token, data).then(() => {
            setIsLoading(false);
            handleTabLoading(false);
            OpenModal(OPEN_ALERT_MODAL, { isOpen: false });
        });
    };

    const handleChange = (value, selectedUser) => {
        console.log(`🚀 ~ handleChange ~ selectedUser:`, selectedUser);
        switch (value) {
            case 'changeOwnership':
                OpenModal(TOGGLE_CHANGE_OWNERSHIP_MODAL, {
                    isOpen: true,
                    title: 'Change ownership',
                    submitTitle: 'Change',
                    data: {
                        modalSize: 'xs',
                        disableBackdropClick: true,
                        owner_email: selectedUser?.owner_email
                    },
                    callBack: (flag, user_id) => {
                        if (flag) {
                            OpenModal(TOGGLE_CHANGE_OWNERSHIP_MODAL, {
                                isOpen: true,
                                isLoading: true,
                                title: 'Change ownership',
                                submitTitle: 'Change',
                                data: {
                                    modalSize: 'xs',
                                    disableBackdropClick: true,
                                    owner_email: selectedUser?.owner_email
                                }
                            });
                            UpdateJobsServices(token, {
                                service_id: selectedUser?.service_id,
                                user_id
                            })
                                .then((d) => {
                                    if (d?.type === 'success') {
                                        getData({ page, type });
                                        OpenModal(
                                            TOGGLE_CHANGE_OWNERSHIP_MODAL,
                                            {
                                                isOpen: false,
                                                isLoading: false
                                            }
                                        );
                                    } else if (d?.type === 'failed') {
                                        OpenModal(
                                            TOGGLE_CHANGE_OWNERSHIP_MODAL,
                                            {
                                                isOpen: true,
                                                isLoading: false
                                            }
                                        );
                                        CommonDispatcher(SHOW_SNACKBAR, {
                                            isShowing: true,
                                            message:
                                                'Failed to change ownership'
                                        });
                                    }
                                })
                                .catch(() => {
                                    OpenModal(TOGGLE_CHANGE_OWNERSHIP_MODAL, {
                                        isOpen: false,
                                        isLoading: false
                                    });
                                    CommonDispatcher(SHOW_SNACKBAR, {
                                        isShowing: true,
                                        message: 'Failed to change ownership'
                                    });
                                });
                        }
                    }
                });
                break;
            case 'expiryDate':
                OpenModal(OPEN_EXTEND_SUBSCRIPTIONS_MODAL, {
                    isOpen: true,
                    title: 'Expiry date',
                    submitTitle: 'Update',
                    data: {
                        modalSize: 'xs',
                        expiry_date: selectedUser?.expiry_date
                    },
                    callBack: (flag, expiry_date) => {
                        if (flag) {
                            UpdateJobsServices(token, {
                                service_id: selectedUser?.service_id,
                                expiry_date
                            }).then(() => {
                                getData({ page, type });
                                OpenModal(OPEN_EXTEND_SUBSCRIPTIONS_MODAL, {
                                    isOpen: false,
                                    isLoading: false
                                });
                            });
                        }
                    }
                });
                break;
            case 'edit':
                history.push(
                    `/edit${Routes.JobsServices}/${selectedUser?.service_id}`
                );
                break;
            case 'delete':
                if (
                    window?.confirm(
                        'Are you sure you want to delete this jobs?'
                    )
                ) {
                    DeleteProductsServices(token, selectedUser?.service_id)
                        .then(() => getSelectedTabData(page))
                        .catch(() => {
                            alert('Something went wrong.');
                        });
                }
                break;
        }
    };

    const renderMenu = ({ service_id, owner_email, expiry_date }) => {
        return (
            <>
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={(e) =>
                        handleClick(e, {
                            service_id,
                            owner_email,
                            expiry_date
                        })
                    }
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    elevation={0}
                    PaperProps={{
                        style: {
                            // maxHeight: ITEM_HEIGHT * 4.5,
                            width: '20ch',
                            border: '1px solid rgb(0 106 194 / 20%)'
                        }
                    }}
                >
                    {options.map((option, i) => (
                        <MenuItem
                            key={option?.value}
                            onClick={() => {
                                handleChange(option?.value, selectedUser);
                                setAnchorEl(null);
                            }}
                        >
                            {option?.title}
                        </MenuItem>
                    ))}
                </Menu>
            </>
        );
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        setIsFilterApplied(false);
    };

    const CommonComponent = () => (
        <CommonPaginationAction
            count={count}
            page={page}
            setPage={setPage}
            total_pages={total_pages}
        />
    );

    const handleCopy = (data) => {
        if (!data) return;
        const flag = copyToClipboard(data);
        CommonDispatcher(SHOW_SNACKBAR, {
            isShowing: !!flag,
            message: 'Copied to clipboard',
            hideAlert: true,
            error: true
        });
    };

    return (
        <>
            <Grid container spacing={0} className="h-100">
                <Grid item xs={12}>
                    <Paper className={classes.root}>
                        <div className="d-flex align-items-center flex-wrap">
                            <div className="flex-grow-1">
                                <TableHeaderFilter
                                    filterDataArray={FirmsTypeList}
                                />
                            </div>
                        </div>
                        {loading || isLoading || isTabLoading ? (
                            <div className="mainLoader">
                                <div className="loader" />
                            </div>
                        ) : (
                            <>
                                <TableContainer className={classes.container}>
                                    <Table
                                        stickyHeader
                                        aria-label="sticky table"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                {tableHeader?.map((column) => (
                                                    <TableCell key={column.id}>
                                                        {column.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {listingsData?.posts &&
                                            listingsData?.posts.length ? (
                                                listingsData?.posts?.map(
                                                    (row) => {
                                                        return (
                                                            <TableRow
                                                                hover
                                                                role="checkbox"
                                                                tabIndex={-1}
                                                                key={
                                                                    row?.service_id
                                                                }
                                                                style={{
                                                                    padding: 0,
                                                                    cursor: 'pointer'
                                                                }}
                                                            >
                                                                <TableCell
                                                                    size={
                                                                        'small'
                                                                    }
                                                                    onClick={(
                                                                        e
                                                                    ) =>
                                                                        e.stopPropagation()
                                                                    }
                                                                >
                                                                    {renderMenu(
                                                                        row
                                                                    )}
                                                                </TableCell>
                                                                <TableCell
                                                                    style={{
                                                                        minWidth:
                                                                            '100px'
                                                                    }}
                                                                    size={
                                                                        'small'
                                                                    }
                                                                >
                                                                    {moment(
                                                                        row?.createdAt
                                                                    ).format(
                                                                        'DD MMM, YYYY'
                                                                    )}
                                                                </TableCell>
                                                                <TableCell
                                                                    style={{
                                                                        minWidth:
                                                                            '100px'
                                                                    }}
                                                                    size={
                                                                        'small'
                                                                    }
                                                                >
                                                                    {moment(
                                                                        row?.expiry_date
                                                                    ).format(
                                                                        'DD MMM, YYYY'
                                                                    )}
                                                                </TableCell>
                                                                <TableCell
                                                                    size={
                                                                        'small'
                                                                    }
                                                                >
                                                                    {row?.status ? (
                                                                        <Chip
                                                                            size="small"
                                                                            label={
                                                                                row?.status
                                                                            }
                                                                            clickable
                                                                            color={
                                                                                row?.status ===
                                                                                'DRAFT'
                                                                                    ? 'secondary'
                                                                                    : 'primary'
                                                                            }
                                                                        />
                                                                    ) : null}
                                                                </TableCell>
                                                                <TableCell
                                                                    size={
                                                                        'small'
                                                                    }
                                                                >
                                                                    {row?.type ? (
                                                                        <Chip
                                                                            size="small"
                                                                            label={
                                                                                row?.type
                                                                            }
                                                                            clickable
                                                                            color={
                                                                                row?.type ===
                                                                                'ALL'
                                                                                    ? 'primary'
                                                                                    : 'secondary'
                                                                            }
                                                                        />
                                                                    ) : null}
                                                                </TableCell>
                                                                <TableCell
                                                                    size={
                                                                        'small'
                                                                    }
                                                                >
                                                                    <div className="d-flex">
                                                                        {row?.is_product ? (
                                                                            <Chip
                                                                                size="small"
                                                                                label={
                                                                                    row?.is_product
                                                                                        ? 'OnSite'
                                                                                        : ''
                                                                                }
                                                                                clickable
                                                                                color={
                                                                                    'primary'
                                                                                }
                                                                            />
                                                                        ) : null}
                                                                        {row?.is_service ? (
                                                                            <Chip
                                                                                size="small"
                                                                                label={
                                                                                    row?.is_service
                                                                                        ? 'HyBrid'
                                                                                        : ''
                                                                                }
                                                                                clickable
                                                                                color={
                                                                                    'secondary'
                                                                                }
                                                                                className="ml-2"
                                                                            />
                                                                        ) : null}
                                                                        {row?.is_hybrid ? (
                                                                            <Chip
                                                                                size="small"
                                                                                label={
                                                                                    row?.is_hybrid
                                                                                        ? 'Remote'
                                                                                        : ''
                                                                                }
                                                                                clickable
                                                                                color={
                                                                                    'secondary'
                                                                                }
                                                                                className="ml-2"
                                                                            />
                                                                        ) : null}
                                                                    </div>
                                                                </TableCell>

                                                                <TableCell
                                                                    style={{
                                                                        minWidth:
                                                                            '100px',
                                                                        cursor: 'pointer'
                                                                    }}
                                                                    size={
                                                                        'small'
                                                                    }
                                                                    onClick={() =>
                                                                        goToListing(
                                                                            Routes.ProductsServices,
                                                                            categories,
                                                                            row?.category_id,
                                                                            row?.service_id
                                                                        )
                                                                    }
                                                                >
                                                                    <a
                                                                        href={
                                                                            row?.name
                                                                        }
                                                                    >
                                                                        {row?.name ||
                                                                            '-'}
                                                                    </a>
                                                                </TableCell>
                                                                <TableCell
                                                                    style={{
                                                                        minWidth:
                                                                            '100px'
                                                                    }}
                                                                    size={
                                                                        'small'
                                                                    }
                                                                >
                                                                    <div className="d-flex flex-direction-column">
                                                                        {row?.discount ||
                                                                            '-'}
                                                                        {row?.discount_note ? (
                                                                            <span className="mt-2">
                                                                                <strong>
                                                                                    Note:
                                                                                </strong>{' '}
                                                                                {
                                                                                    row?.discount_note
                                                                                }
                                                                            </span>
                                                                        ) : null}
                                                                    </div>
                                                                </TableCell>

                                                                <TableCell
                                                                    size={
                                                                        'small'
                                                                    }
                                                                >
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="d-flex mr-2 ">
                                                                            {row?.owner_name ||
                                                                                '-'}
                                                                        </div>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell
                                                                    size={
                                                                        'small'
                                                                    }
                                                                >
                                                                    <div className="d-flex align-items-center">
                                                                        {row?.owner_email ||
                                                                            '-'}
                                                                        <IconButton
                                                                            onClick={(
                                                                                e
                                                                            ) => {
                                                                                e.stopPropagation();
                                                                                handleCopy(
                                                                                    row?.owner_email
                                                                                );
                                                                            }}
                                                                            className=" ml-1"
                                                                        >
                                                                            <FileCopy />
                                                                        </IconButton>
                                                                    </div>
                                                                </TableCell>

                                                                <TableCell
                                                                    size={
                                                                        'small'
                                                                    }
                                                                >
                                                                    {row?.bizservice_tags &&
                                                                    row
                                                                        ?.bizservice_tags
                                                                        ?.length
                                                                        ? row?.bizservice_tags.map(
                                                                              (
                                                                                  d
                                                                              ) => {
                                                                                  return (
                                                                                      <Chip
                                                                                          size="small"
                                                                                          className="mr-2 my-2"
                                                                                          key={
                                                                                              d?.id
                                                                                          }
                                                                                          label={
                                                                                              d?.tag_text
                                                                                          }
                                                                                          clickable
                                                                                      />
                                                                                  );
                                                                              }
                                                                          )
                                                                        : '-'}
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    }
                                                )
                                            ) : (
                                                <tr>
                                                    <td
                                                        colSpan={
                                                            tableHeader.length +
                                                            1
                                                        }
                                                        className="dataNotAvailable"
                                                    >
                                                        Data not available
                                                    </td>
                                                </tr>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    className={classes.footer}
                                    hidden={
                                        listingsData?.posts &&
                                        !listingsData?.posts.length
                                    }
                                    rowsPerPageOptions={[]}
                                    component="div"
                                    count={count}
                                    rowsPerPage={listingsData?.page_size}
                                    page={page}
                                    ActionsComponent={CommonComponent}
                                    onPageChange={handleChangePage}
                                />
                            </>
                        )}
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
};
export default connect(null, {
    GetAllProductsServicesList,
    DeleteProductsServices,
    CommonDispatcher,
    OpenModal,
    UpdateJobsServices
})(AllProductsServices);
