import {
    Button,
    Container,
    Grid,
    InputLabel,
    Typography
} from '@material-ui/core';
import SlideTransition from '@material-ui/pickers/views/Calendar/SlideTransition';
import imageCompression from 'browser-image-compression';
import moment from 'moment';
import objectPath from 'object-path';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { connect, useSelector } from 'react-redux';
import useStateRef from 'react-usestateref';
import { CommonDispatcher } from '../../../redux/Common/action';
import { SHOW_SNACKBAR } from '../../../redux/Common/actionTypes';
import {
    UpdateProductsServices,
    fetchOneProductsServicesData,
    uploadImages
} from '../../../redux/Dashboard/actions';
import { RootState } from '../../../redux/store';
import { stripHtml } from '../../../utils/common';
import CardComponent from '../../Common/CardComponent';
import DragAndDrop from '../../DragAndDrop';
import AutoCompleteAddressComponent from '../../InputComponents/AutoCompleteAddress';
import RenderAutocompleteTextField from '../../InputComponents/AutocompleteTextField';
import RenderDatePicker from '../../InputComponents/DatePickerComponent/RenderDatePicker';
import EditorComponent from '../../InputComponents/Editor';
import RenderMultiToggleSwitch from '../../InputComponents/MultiToggleSwitch/RenderMultiToggleSwitch';
import RenderSelectField from '../../InputComponents/SelectField/RenderSelectField';
import RenderTextField from '../../InputComponents/TextField/RenderTextField';
import style from './AddEdit.module.scss';

let typeList = [
    { title: 'All Registered Users', value: 'ALL', disabled: false },
    { title: 'Investor', value: 'INVESTOR', disabled: false },
    { title: 'Startup', value: 'STARTUP', disabled: false },
    { title: 'Private Investor', value: 'PRIVATE_INVESTOR', disabled: false },
    { title: 'Ecosystems', value: 'ECOSYSTEM', disabled: false },
    { title: 'Public', value: 'PUBLIC', disabled: false }
];

let eventTypeList = [
    { title: 'Product', value: 'PRODUCTS', disabled: false },
    { title: 'Service', value: 'SERVICES', disabled: false }
];
function EditProductsServices(props: any) {
    // const history = useHistory();
    const categoryData =
        useSelector(
            (store: RootState) => store?.commonAppData.categories?.services
        ) || [];
    const companyFoundedList =
        useSelector((store: RootState) => store?.commonAppData.foundedList) ||
        [];
    const [state, setState, refState] = useStateRef<any>({});
    const [firstLoad, setFirstLoad] = useState(true);

    const [, setProgress, progressRef] = useStateRef(0);
    const [isActive, setIsActive] = useState(false);
    const [cookies] = useCookies(['pgAdminToken']);
    const [isPageLoading, setPageLoading] = useState(false);
    const [event_type, setEventType] = useState([]);
    const [error, setError, errorRef] = useStateRef({}) as [any, any, any];
    const [categories, setCategories] = useState(categoryData);
    const [category, setCategory] = useState();

    const [filteredCategories, setFilteredCategories] = useState([]);
    const [founded_on, setFoundedOn] = useState();
    const [tagName, setTagsName] = useState<object[]>([]);
    const [tagSize, setTagsSize] = useState<number>(0);
    const [MAX_TAGS_SIZE] = useState<number>(10);
    const [type, setType] = useState(typeList[0].value);
    const [images, setImages, imagesRef] = useStateRef([]);
    const [description, setDescription] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');

    const [whatsapp_phone, setWhatsAppPhone] = useState();
    const [discount, setDiscount] = useState();
    const [discount_note, setDescribeDiscount] = useState();
    const [website, setWebsite] = useState('');
    const [expiry_date, setExpiryDate] = useState<any>(moment().add(5, 'year'));
    const [isLoading, setLoading] = useState(false);
    const [detailsData, setProductServicesData] = useState({});
    const [snackBarState, setSnackBarState] = useState({
        open: false,
        message: '',
        Transition: SlideTransition
    });

    const [address, setAddress] = useState({
        area: '',
        city: '',
        country: '',
        address: ''
    });
    const [coordinates, setCoordinates] = useState({
        lat: 51.50853,
        lng: -0.12574
    });

    useEffect(() => {
        if (
            categories.length &&
            objectPath.get(categories, [0, 'value'], '') !== 'select'
        ) {
            categories.unshift({
                title: 'Select Category',
                value: 'select',
                disabled: true
            });
        }
        setCategories([...categories]);
        let data = [...categories];
        data.shift();
        setFilteredCategories(data);
        const post_id = props?.match?.params?.id;

        setPageLoading(true);
        props
            ?.fetchOneProductsServicesData(cookies?.pgAdminToken, post_id)
            .then((response) => {
                if (objectPath.get(response, 'type') === 'success') {
                    setInitialState(objectPath.get(response, 'response'));
                    setProductServicesData(
                        objectPath.get(response, 'response')
                    );
                    setPageLoading(false);
                }
            });
        setPageLoading(false);
    }, []);

    const setInitialState = (detailsData) => {
        const images = objectPath.get(detailsData, 'images', []);
        let imageList = [];
        images.forEach((d) => {
            imageList.push({
                id: d.img_id,
                src: d.sm,
                apiName: d.rank,
                name: '0' + d.rank,
                d
            });
        });
        setImages(imageList);
        const tags = objectPath
            .get(detailsData, 'categories', [])
            .map((d) => d.category_id);
        if (tags.length) {
            categoryData.pop();
            const initialTags = categoryData.filter((d) =>
                tags.includes(d.value)
            );
            setTagsName(initialTags as object[]);
            setTagsSize(initialTags.length as number);
        }
        setCategory(detailsData?.primary_category?.category_id);

        setName(objectPath.get(detailsData, 'name', ''));
        setFoundedOn(objectPath.get(detailsData, 'founded_on', ''));
        setType(objectPath.get(detailsData, 'type', ''));
        setWebsite(objectPath.get(detailsData, 'website', ''));
        setPhone(objectPath.get(detailsData, 'phone', ''));
        setWhatsAppPhone(objectPath.get(detailsData, 'whatsapp_phone', ''));
        setDescription(objectPath.get(detailsData, 'description', ''));
        setDiscount(objectPath.get(detailsData, 'discount', ''));
        setDescribeDiscount(objectPath.get(detailsData, 'discount_note', ''));
        setExpiryDate(objectPath.get(detailsData, 'expiry_date', ''));

        setAddress({
            area: detailsData?.area || '',
            city: detailsData?.city || '',
            country: detailsData?.country || '',
            address: detailsData?.address || ''
        });
        setCoordinates({
            lat: objectPath.get(detailsData, 'latitude', ''),
            lng: objectPath.get(detailsData, 'longitude', '')
        });
        if (detailsData?.is_product && detailsData?.is_service) {
            setEventType(['PRODUCTS', 'SERVICES']);
        } else if (detailsData?.is_product) {
            setEventType(['PRODUCTS']);
        } else if (detailsData?.is_service) {
            setEventType(['SERVICES']);
        }
        setPageLoading(false);
    };

    const getUniqueId = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            let r = (Math.random() * 16) | 0,
                v = c == 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    };

    const handleSelectionChange = (
        event: React.MouseEvent<HTMLElement>,
        value: []
    ) => {
        if (!value.length) {
            setError({ ...error, event_type: true });
        } else {
            setError({ ...error, event_type: false });
        }
        setEventType(value);
    };
    const handleFoundedChange = (e) => {
        setFoundedOn(e.target.value);
        if (!e.target.value) {
            setError({ ...error, founded: true });
        } else {
            setError({ ...error, founded: false });
        }
    };
    const handleExpiryDate = (date) => {
        const exp_date: any = moment(date);
        setExpiryDate(exp_date);
    };
    const handleAddressChange = (address) => {
        if (!address.address) {
            setError({ ...error, location: true });
        } else {
            setError({ ...error, location: false });
        }
        setAddress(address);
    };

    const onDrop = useCallback(async (files) => {
        if (files && files.length) {
            const currentIndex = imagesRef.current.length;
            const file_mame = files.map((file) =>
                file.name ? file.name : file.path ? file.path : 'Unknown'
            );
            files = files.map(
                (file, i) =>
                    new File([file], `${i + currentIndex}`, { type: file.type })
            );
            const updateProgress = (p) => {
                if (p == 100) {
                    setProgress(progressRef.current + 1);
                    if (files.length == progressRef.current) {
                        setIsActive(false);
                        setProgress(0);
                    }
                }
            };
            setIsActive(true);
            let prom = [];
            for (let index in files) {
                prom.push(
                    imageCompression(files[index], {
                        maxSizeMB: 1,
                        onProgress: updateProgress
                    })
                );
            }
            Promise.all(prom).then((files) => {
                files.map((file, i) => {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        setImages((state) => [
                            ...state,
                            {
                                file,
                                id: getUniqueId(),
                                //@ts-ignore
                                src: e.target.result,
                                apiName: file.name,
                                name: file_mame[i],
                                size: file.size
                                    ? `${(
                                          (file.size / 1024) *
                                          0.0009765625
                                      ).toFixed(2)} MB`
                                    : 0
                            }
                        ]);
                    };
                    reader.readAsDataURL(file);
                    return file;
                });
            });
        }
    }, []);

    const handleDeleteImage = (id) => {
        if (id) {
            setImages((prevImages) =>
                prevImages.filter((image) => image.id !== id)
            );
        }
    };

    const handleCategoryChange = (e) => {
        const value = e.target.value;
        if (!value) {
            setError({ ...error, category: true });
        } else {
            setError({ ...error, category: false });
        }
        setCategory(value);
        let data = categories.filter((d) => d.value !== value);
        data.shift();
        setFilteredCategories(data);
    };

    const handleDescriptionChange = (value: any) => {
        if (!stripHtml(value) && !firstLoad) {
            setError({ ...error, description: true });
        } else {
            setError({ ...error, description: false });
        }

        setFirstLoad(false);
        setDescription(value);
    };
    const handleTagChange = (e: ChangeEvent<{ value: unknown }>, value) => {
        setTagsName(value as object[]);
        setTagsSize(value.length as number);
        if (tagSize === MAX_TAGS_SIZE - 1) {
            setPopper('none');
        } else if (tagSize === MAX_TAGS_SIZE) {
            setPopper('block');
        }
    };
    const setPopper = (value) => {
        const popper = document.querySelector('.MuiAutocomplete-popper');
        if (popper) {
            //@ts-ignore
            popper.style.display = value;
        }
    };

    const handleNameChange = (e) => {
        setName(e.target.value);
        if (!e.target.value) {
            setError({ ...error, name: true });
        } else {
            setError({ ...error, name: false });
        }
    };
    const handleDiscountChange = (e) => {
        setDiscount(e.target.value);
    };
    const handleDescribeDiscountChange = (e) => {
        setDescribeDiscount(e.target.value);
    };

    const handleWebsiteChange = (e) => {
        setWebsite(e.target.value);
    };

    const handlePhoneChange = (e) => {
        setPhone(e.target.value);
    };

    const handleWhatsAppPhoneChange = (e) => {
        setWhatsAppPhone(e.target.value);
    };

    const handleTypeChange = (e) => {
        setType(e.target.value);
        if (!e.target.value) {
            setError({ ...error, type: true });
        } else {
            setError({ ...error, type: false });
        }
    };

    const getImages = (images, id) => {
        let formData = new FormData();
        formData.append('id', id);
        formData.append('type', 'BIZ_SERVICES');
        images.forEach((image: any) => {
            formData.append('files', image.file as any, image.apiName);
        });
        return formData;
    };
    const handleSubmit = async (e, status = null) => {
        e.preventDefault();
        setLoading(true);
        const mergedLocation = `${address?.city ? `${address?.city}, ` : ''}${
            address?.country ? address?.country : ''
        }`;
        const categories = tagName.map((d: any) => d.value);
        let data: any = {
            type,
            name: name || null,
            website: website || null,
            phone: phone || null,
            whatsapp_phone,
            founded_on,
            description: description || null,
            merged_location: mergedLocation || null,
            city: address?.city || null,
            country: address?.country || null,
            area: address?.area || null,
            address: address?.address || null,
            latitude: address?.address ? coordinates?.lat : null,
            longitude: address?.address ? coordinates?.lng : null,
            category_id: category || null,
            categories: categories && categories.length ? categories : null,
            discount_note,
            discount,
            expiry_date: moment(expiry_date).format('YYYY-MM-DD')
        };

        if (event_type?.length === 1) {
            if (event_type[0] === 'PRODUCTS') {
                data.is_product = true;
                data.is_service = false;
            } else {
                data.is_service = true;
                data.is_product = false;
            }
        } else {
            data.is_product = true;
            data.is_service = true;
        }

        const validate = () => {
            let isValid = true;

            if (!event_type.length) {
                setError({ ...errorRef.current, event_type: true });
                isValid = false;
            }
            if (!data?.type) {
                setError({ ...errorRef.current, type: true });
                isValid = false;
            }

            if (!data?.name) {
                setError({ ...errorRef.current, name: true });
                isValid = false;
            }

            if (!data?.address) {
                setError({ ...errorRef.current, location: true });
                isValid = false;
            }

            if (!data?.category_id) {
                setError({ ...errorRef.current, category: true });
                isValid = false;
            }

            if (!data?.founded_on) {
                setError({ ...errorRef.current, founded: true });
                isValid = false;
            }

            // if (!stripHtml(data?.description)) {
            //     setError({ ...errorRef.current, description: true });
            //     isValid = false;
            // }

            return isValid;
        };
        if (status) {
            data.status = status;
        }

        if (!status && !validate()) {
            setLoading(false);
            setSnackBarState({
                ...snackBarState,
                message: 'Please complete the missing fields',
                open: true
            });
            return;
        }

        const service_id = props?.match?.params?.id;
        try {
            data = Object.assign({}, data, {
                service_id,
                images: imagesRef.current
                    .filter((d) => !d.file)
                    .map((d: any) => ({
                        img_id: objectPath.get(d, 'd.img_id'),
                        rank: objectPath.get(d, 'd.rank')
                    })),
                user_id: objectPath.get(detailsData, 'user_id', '')
            });
            props
                ?.UpdateProductsServices(cookies?.pgAdminToken, data)
                .then((r) => {
                    const images1 = images.filter((d) => d.file);
                    if (images1.length) {
                        props
                            ?.uploadImages(
                                cookies?.pgAdminToken,
                                getImages(images1, props?.match?.params?.id)
                            )
                            .then((d) => {
                                if (objectPath.get(d, 'type') === 'success') {
                                    props?.CommonDispatcher(SHOW_SNACKBAR, {
                                        isShowing: true,
                                        message: 'Post updated successfully.'
                                    });
                                    // history.push(Routes.productsServices.index);
                                } else {
                                    props?.CommonDispatcher(SHOW_SNACKBAR, {
                                        isShowing: true,
                                        message: `Failed to updated the post.`
                                    });
                                }
                                setLoading(false);
                            });
                    } else {
                        setLoading(false);
                        if (objectPath.get(r, 'type') === 'success') {
                            props?.CommonDispatcher(SHOW_SNACKBAR, {
                                isShowing: true,
                                message: 'Post updated successfully.'
                            });
                            // history.push(Routes.productsServices.index);
                        } else {
                            props?.CommonDispatcher(SHOW_SNACKBAR, {
                                isShowing: true,
                                message: 'Failed to updated the post.'
                            });
                        }
                    }
                });
        } catch (e) {
            setLoading(false);
            props?.CommonDispatcher(SHOW_SNACKBAR, {
                isShowing: true,
                message: 'Something went wrong.'
            });
        }
    };
    return (
        <div className="pageContainer">
            <title>{'Products / Services'}</title>
            <Container maxWidth="md">
                {isPageLoading ? (
                    <div className="mainLoader">
                        <div className="loader" />
                    </div>
                ) : (
                    <Grid item xs={12} md={8} sm={8} className={style.gridItem}>
                        <CardComponent
                            cardClasses={`text-hover-bgColorWhite overflow-unset`}
                        >
                            <form
                                className={style.form}
                                onSubmit={handleSubmit}
                            >
                                <Grid container className={style.gridContainer}>
                                    <div className="px-0 mb-5">
                                        <Typography
                                            variant="h6"
                                            className={style.title}
                                        >
                                            Update a products or Service
                                        </Typography>
                                    </div>
                                    <Grid container>
                                        <Grid item xs={12} className="py-1">
                                            <DragAndDrop
                                                onDrop={onDrop}
                                                images={images}
                                                isLoading={isActive}
                                                deleteImage={handleDeleteImage}
                                                accept={
                                                    'image/png, image/jpg, image/jpeg'
                                                }
                                                maxFiles={10}
                                                label="Upload Photos (up to 10 Photos)"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={6} className="mt-3">
                                        <RenderMultiToggleSwitch
                                            id="event_type"
                                            toggleButtonGroupId={'event_type'}
                                            label="Select type* (multi-select)"
                                            value={event_type}
                                            error={error.event_type}
                                            helperText={
                                                'Products/Services is mandatory'
                                            }
                                            onChange={handleSelectionChange}
                                            dataArray={eventTypeList}
                                        />
                                    </Grid>

                                    <Grid
                                        container
                                        spacing={3}
                                        className="mt-3"
                                    >
                                        <Grid item xs={12} className="py-1">
                                            <RenderSelectField
                                                label="Category *"
                                                id="category"
                                                inputId="select-category"
                                                value={category}
                                                error={error.category}
                                                helperText={
                                                    'Category is mandatory.'
                                                }
                                                dropdownListHeight={250}
                                                onChange={handleCategoryChange}
                                                dataArray={categories}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        container
                                        spacing={3}
                                        className="mt-3"
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            md={12}
                                            className="py-1"
                                        >
                                            <RenderTextField
                                                id="name"
                                                value={name}
                                                label="Name *"
                                                error={error.name}
                                                helperText={
                                                    error.name
                                                        ? 'Name is mandatory.'
                                                        : null
                                                }
                                                onChange={handleNameChange}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        container
                                        spacing={3}
                                        className="mt-3"
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            className="py-1"
                                        >
                                            <RenderTextField
                                                id="website"
                                                value={website}
                                                label="Website"
                                                inputMode="url"
                                                placeholder="www.yourbusiness.co.uk"
                                                onChange={handleWebsiteChange}
                                            />
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            className="py-1"
                                        >
                                            <RenderSelectField
                                                label="Founding year *"
                                                id="founded_on"
                                                error={error.founded}
                                                helperText={
                                                    'Foundation year is mandatory.'
                                                }
                                                inputId="select-founded_on"
                                                value={founded_on}
                                                dropdownListHeight={250}
                                                onChange={handleFoundedChange}
                                                dataArray={companyFoundedList}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        spacing={3}
                                        className="mt-3"
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            className="py-1"
                                        >
                                            <RenderTextField
                                                id="phone"
                                                value={phone}
                                                label="Phone"
                                                type="number"
                                                inputMode="tel"
                                                onChange={handlePhoneChange}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            className="py-1"
                                        >
                                            <RenderTextField
                                                id="whatsapp_phone"
                                                value={whatsapp_phone}
                                                type="number"
                                                label="WhatsApp Phone"
                                                inputMode="tel"
                                                onChange={
                                                    handleWhatsAppPhoneChange
                                                }
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        container
                                        spacing={3}
                                        className="mt-3"
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            md={12}
                                            className="py-1"
                                        >
                                            <RenderTextField
                                                id="discount"
                                                value={discount}
                                                label="Discount/offer/trial"
                                                onChange={handleDiscountChange}
                                            />
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            md={12}
                                            className="py-1"
                                        >
                                            <div>
                                                Describe how to access the
                                                discount/offer/trial
                                            </div>
                                            <textarea
                                                id="describe"
                                                value={discount_note}
                                                onChange={
                                                    handleDescribeDiscountChange
                                                }
                                                rows={4}
                                                className="w-100 mt-2"
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        container
                                        spacing={3}
                                        className="mt-3"
                                    >
                                        <Grid item xs={12}>
                                            <EditorComponent
                                                initialValue={description}
                                                placeholder="Enter your description here..."
                                                label="Details about the product/service *"
                                                handleDescriptionChange={
                                                    handleDescriptionChange
                                                }
                                            />

                                            {error.description ? (
                                                <InputLabel error={true}>
                                                    Description is mandatory.
                                                </InputLabel>
                                            ) : null}
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            className="py-1 d-flex flex-direction-column"
                                        >
                                            <RenderDatePicker
                                                shrink={true}
                                                value={expiry_date}
                                                showInputLabel={true}
                                                onChange={(data) => {
                                                    handleExpiryDate(data);
                                                }}
                                                label="Expiry date *"
                                                format="DD-MM-YYYY"
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        container
                                        spacing={3}
                                        className="mt-3"
                                    >
                                        <Grid item xs={12} className="py-1">
                                            <AutoCompleteAddressComponent
                                                id="location"
                                                error={error.location}
                                                coordinates={coordinates}
                                                defaultValue={objectPath.get(
                                                    address,
                                                    'address',
                                                    ''
                                                )}
                                                setCoordinates={setCoordinates}
                                                setAddress={handleAddressChange}
                                                autoComplete="off"
                                                label="Enter the business address *"
                                                hideMap={true}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={12}
                                            className="py-1"
                                        >
                                            <RenderSelectField
                                                label="Visibility *"
                                                id="type"
                                                error={error.type}
                                                helperText={
                                                    'Visibility is mandatory.'
                                                }
                                                inputId="select-type"
                                                value={type}
                                                onChange={handleTypeChange}
                                                dataArray={typeList}
                                            />
                                        </Grid>

                                        <Grid item xs={12} className="py-1">
                                            <RenderAutocompleteTextField
                                                id="tags"
                                                value={tagName}
                                                label={`Add additional tags (maximum ${MAX_TAGS_SIZE})`}
                                                options={
                                                    tagSize < MAX_TAGS_SIZE
                                                        ? filteredCategories
                                                        : []
                                                }
                                                onChange={handleTagChange}
                                                inputPlaceholder="Search tags"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Button
                                        type="submit"
                                        color={'primary'}
                                        variant={'contained'}
                                        className="mt-6"
                                    >
                                        Update Post
                                    </Button>
                                </Grid>
                            </form>
                        </CardComponent>
                    </Grid>
                )}
            </Container>
        </div>
    );
}

export default connect(null, {
    fetchOneProductsServicesData,
    uploadImages,
    CommonDispatcher,
    UpdateProductsServices
})(EditProductsServices);
