import { API_PATH } from '../../constants';
import axios from 'axios';
import {
    BUISNESS_MODEL_MASTER,
    SET_ADMIN_PROFILE,
    SET_CATEGORIES_DATA,
    SET_ECOSYSTEM_TYPE_LIST,
    SET_FILTER_DATA,
    SET_FIRMS_CLASSIFY,
    SET_INVESTMENTS_TYPE_LIST,
    SET_REGIONS,
    SET_SDG_GOALS,
    SET_SECTORS_DATA,
    SET_STAGES
} from './actionTypes';
import { SET_STARTUP_TYPE } from './actionTypes';

export const OpenModal = (type, payload) => {
    return (dispatch) => {
        dispatch({ type, payload });
    };
};
export const SetFilter = (payload) => {
    return { type: SET_FILTER_DATA, payload };
};

export const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
};

export const createAllCommonCategories = (categoriesJson = []) => {
    let commonCategories = {
        services: [],
        jobs: [],
        events: [],
        stories: []
    };
    if (categoriesJson && categoriesJson.length) {
        categoriesJson.forEach((d) => {
            if (d.type === 'SERVICE') {
                createObject(commonCategories, 'services', d);
            }
            if (d.type === 'JOB_SERVICE') {
                createObject(commonCategories, 'jobs', d);
            }
            if (d.type === 'EVENT') {
                createObject(commonCategories, 'events', d);
            }
            if (d.type === 'STORIES') {
                createObject(commonCategories, 'stories', d);
            }
        });
    }
    return commonCategories;
};

export const UploadDocument = (data) => {
    return (dispatch) => {
        return axios
            .post(API_PATH + '/upload/upload-doc', data)
            .then((response) => {
                return { type: 'success', response: response?.data?.data };
            })
            .catch((error) => {
                console.log(error?.response);
                return { type: 'error', response: error?.response };
            });
    };
};

export const GetInvestmentsTypeMaster = () => {
    return (dispatch) => {
        return axios
            .get(API_PATH + '/metadata/investments_type_master')
            .then((response) => {
                let investmentsType = response?.data?.data?.investmentsType;
                if (investmentsType && investmentsType?.length) {
                    investmentsType = investmentsType
                        .map((d) => ({
                            title: d?.name,
                            value: d?.id
                        }))
                        .sort((a, b) => a?.value - b?.value);
                }
                dispatch({
                    type: SET_INVESTMENTS_TYPE_LIST,
                    payload: investmentsType
                });
                return { type: 'success', response: { investmentsType } };
            })
            .catch((error) => {
                console.log(error?.response);
                if (!error?.response) {
                    return {
                        type: 'error',
                        response: {
                            message:
                                'Server error. Please try again after sometime.'
                        }
                    };
                } else {
                    return { type: 'error', response: error?.response };
                }
            });
    };
};

export const GetEcosystemTypeMaster = () => {
    return (dispatch) => {
        return axios
            .get(API_PATH + '/metadata/ecosystem_type_master')
            .then((response) => {
                let ecosystemTypes = response?.data?.data?.EcosystemTypeMaster;
                if (ecosystemTypes && ecosystemTypes?.length) {
                    ecosystemTypes = ecosystemTypes
                        .map((d) => ({
                            title: d?.name,
                            value: d?.id
                        }))
                        .sort((a, b) => a?.value - b?.value);
                }
                dispatch({
                    type: SET_ECOSYSTEM_TYPE_LIST,
                    payload: ecosystemTypes
                });
                return { type: 'success', response: { ecosystemTypes } };
            })
            .catch((error) => {
                console.log(error?.response);
                if (!error?.response) {
                    return {
                        type: 'error',
                        response: {
                            message:
                                'Server error. Please try again after sometime.'
                        }
                    };
                } else {
                    return { type: 'error', response: error?.response };
                }
            });
    };
};

const createObject = (commonCategories, key, d) => {
    return commonCategories[key].push({
        title: d?.label,
        value: d?.category_id,
        name: d?.url_identifier,
        type: d?.type,
        subCategories: d?.sub_categories
    });
};

export const sortData = (data, label = 'name') => {
    if (data && data.length) {
        return data.sort((a, b) => {
            if (a[label] < b[label]) {
                return -1;
            }
            if (a[label] > b[label]) {
                return 1;
            }
            return 0;
        });
    } else return [];
};

export const GetReduxState = (store) => {
    return typeof store !== 'undefined' && store !== null
        ? store.getState()
        : {};
};

export const CommonDispatcher = (type, payload) => {
    return (dispatch) => {
        dispatch({ type, payload });
    };
};

export const CheckUserExists = (token: string, data: object) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/get-user-email`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (e) {
            return { type: 'failed' };
        }
    };
};

export const GetAdminProfile = (token: string) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'get',
                url: `${API_PATH}/admin/profile`,
                headers: { Authorization: token }
            };
            const payload = (await axios(config as any))?.data?.data;
            dispatch({
                type: SET_ADMIN_PROFILE,
                payload
            });
            return { type: 'success', response: payload };
        } catch (e) {
            return { type: 'failed' };
        }
    };
};

export const GetCategories = (token: string) => {
    return async (dispatch) => {
        try {
            const config = {
                method: 'get',
                url: `${API_PATH}/metadata/categories`,
                headers: { Authorization: token }
            };
            const payload = (await axios(config as any))?.data?.data
                ?.categories;
            dispatch({
                type: SET_CATEGORIES_DATA,
                payload
            });
            return payload;
        } catch (e) {
            return { type: 'failed' };
        }
    };
};

export const GetSectors = (token: string) => {
    return async (dispatch) => {
        try {
            const config = {
                method: 'get',
                url: `${API_PATH}/metadata/sectors`,
                headers: { Authorization: token }
            };
            let sectors = (await axios(config as any))?.data?.data?.sectors;
            if (sectors && sectors?.length) {
                sectors = sectors
                    .map((d) => ({
                        id: d?.sector_id,
                        title: d?.label
                    }))
                    .sort((a, b) => a?.id - b?.id);
            }
            dispatch({
                type: SET_SECTORS_DATA,
                payload: { sectors }
            });
            return { type: 'success', response: { sectors } };
        } catch (e) {
            return { type: 'failed' };
        }
    };
};

export const GetFirmsList = (token: string, keyword) => {
    return async (dispatch) => {
        try {
            const config = {
                method: 'get',
                url: keyword
                    ? `${API_PATH}/metadata/firms?keyword=${keyword}`
                    : `${API_PATH}/metadata/firms`,
                headers: { Authorization: token }
            };
            const firms = (await axios(config as any))?.data?.data?.firms;
            return { type: 'success', response: { firms } };
        } catch (e) {
            return { type: 'failed' };
        }
    };
};

export const GetOneFirm = (token: string, data) => {
    return async (dispatch) => {
        try {
            const config = {
                method: 'post',
                url: `${API_PATH}/admin/firm`,
                headers: { Authorization: token },
                data
            };
            const firm = (await axios(config as any))?.data?.data?.firm;
            return { type: 'success', response: { firm } };
        } catch (e) {
            return { type: 'failed' };
        }
    };
};

export const UploadImages = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/upload-image`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const UploadFirmLogo = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/upload/firm-picture`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            console.log(error);
            return { type: 'failed', response: error?.response };
        }
    };
};

export const GetCountries = (token: string) => {
    return async (dispatch) => {
        try {
            const config = {
                method: 'get',
                url: `${API_PATH}/metadata/countries`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data
                ?.countries;
            let countries =
                response && response.length
                    ? response
                          .map((d) => ({
                              title: d?.label,
                              value: d?.country_id
                          }))
                          .sort((a, b) => a?.value - b?.value)
                    : [];
            return { type: 'success', response: countries };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};
export const GetFirmsClassify = () => {
    return (dispatch) => {
        return axios
            .get(API_PATH + '/metadata/firm_classify_master')
            .then((response) => {
                let firms = response?.data?.data?.firmclassify;
                if (firms && firms?.length) {
                    firms = firms.map((d) => ({
                        value: d?.firm_classify_id,
                        title: d?.label
                    }));
                }
                dispatch({ type: SET_FIRMS_CLASSIFY, payload: firms });
                return { type: 'success', response: firms };
            })
            .catch((e) => {
                const error = e?.response;
                if (!error) {
                    return {
                        type: 'error',
                        response: {
                            message:
                                'Server error. Please try again after sometime.'
                        }
                    };
                } else {
                    return { type: 'error', response: error };
                }
            });
    };
};

export const GetStartupTypeMaster = () => {
    return (dispatch) => {
        return axios
            .get(API_PATH + '/metadata/startup_type_master')
            .then((response) => {
                let startupType = response?.data?.data?.startupType;
                if (startupType && startupType?.length) {
                    startupType = startupType.map((d) => ({
                        title: d?.name,
                        value: d?.id
                    }));
                }
                dispatch({ type: SET_STARTUP_TYPE, payload: startupType });
                return { type: 'success', response: { startupType } };
            })
            .catch((error) => {
                console.log(error?.response);
                if (!error?.response) {
                    return {
                        type: 'error',
                        response: {
                            message:
                                'Server error. Please try again after sometime.'
                        }
                    };
                } else {
                    return { type: 'error', response: error?.response };
                }
            });
    };
};

export const GetBusinessModelMaster = () => {
    return (dispatch) => {
        return axios
            .get(API_PATH + '/metadata/buisness_model_master')
            .then((response) => {
                let businessModel = response?.data?.data?.buisnessModel;
                if (businessModel && businessModel?.length) {
                    businessModel = businessModel
                        .map((d) => ({
                            title: d?.name,
                            value: d?.id
                        }))
                        .sort((a, b) => a?.value - b?.value);
                }
                dispatch({
                    type: BUISNESS_MODEL_MASTER,
                    payload: businessModel
                });
                return { type: 'success', response: businessModel };
            })
            .catch((error) => {
                console.log(error?.response);
                if (!error?.response) {
                    return {
                        type: 'error',
                        response: {
                            message:
                                'Server error. Please try again after sometime.'
                        }
                    };
                } else {
                    return { type: 'error', response: error?.response };
                }
            });
    };
};

const changeElePos = (dataArray, id) => {
    let data = dataArray.filter((d) => d?.value !== id);
    let lastElement = data.pop();
    data.unshift(lastElement);
    return data;
};

export const GetStages = () => {
    return (dispatch) => {
        return axios
            .get(API_PATH + '/metadata/stages')
            .then((response) => {
                let stages = response?.data?.data?.stages;
                if (stages && stages?.length) {
                    stages = stages.map((d) => ({
                        value: d?.funding_stage_id,
                        title: d?.label
                    }));
                    let CURRENTLY_RAISING_LIST = changeElePos(stages, 11);
                    let STAGE_LIST = changeElePos(stages, 10);
                    dispatch({
                        type: SET_STAGES,
                        payload: {
                            stages: {
                                stages,
                                STAGE_LIST,
                                CURRENTLY_RAISING_LIST
                            }
                        }
                    });
                    return {
                        type: 'success',
                        response: {
                            stages: {
                                stages,
                                STAGE_LIST,
                                CURRENTLY_RAISING_LIST
                            }
                        }
                    };
                } else {
                    dispatch({
                        type: SET_STAGES,
                        payload: {
                            stages: {
                                stages: [],
                                STAGE_LIST: [],
                                CURRENTLY_RAISING_LIST: []
                            }
                        }
                    });
                    return {
                        type: 'success',
                        response: {
                            stages: {
                                stages: [],
                                STAGE_LIST: [],
                                CURRENTLY_RAISING_LIST: []
                            }
                        }
                    };
                }
            })
            .catch((error) => {
                console.log(error?.response);
                if (!error?.response) {
                    return {
                        type: 'error',
                        response: {
                            message:
                                'Server error. Please try again after sometime.'
                        }
                    };
                } else {
                    return { type: 'error', response: error?.response };
                }
            });
    };
};

export const GetSdgGoals = () => {
    return (dispatch) => {
        return axios
            .get(API_PATH + '/metadata/sdg-sectors')
            .then((response) => {
                let sdgGoals = response?.data?.data?.stages;
                if (sdgGoals && sdgGoals?.length) {
                    sdgGoals = sdgGoals
                        .map((d) => ({
                            id: d?.sdg_sector_id,
                            title: d?.label
                        }))
                        .sort((a, b) => a?.id - b?.id);
                }
                dispatch({ type: SET_SDG_GOALS, payload: sdgGoals });
                return { type: 'success', response: sdgGoals };
            })
            .catch((error) => {
                console.log(error?.response);
                if (!error?.response) {
                    return {
                        type: 'error',
                        response: {
                            message:
                                'Server error. Please try again after sometime.'
                        }
                    };
                } else {
                    return { type: 'error', response: error?.response };
                }
            });
    };
};

export const GetRegions = () => {
    return (dispatch) => {
        return axios
            .get(API_PATH + '/metadata/regions')
            .then((response) => {
                let regions = response?.data?.data?.regions;
                if (regions && regions?.length) {
                    regions = regions
                        .map((d) => ({
                            id: d?.region_id,
                            title: d?.label,
                            countries: d?.countries
                        }))
                        .sort((a, b) => a?.id - b?.id);
                }
                dispatch({ type: SET_REGIONS, payload: regions });
                return { type: 'success', response: { regions } };
            })
            .catch((error) => {
                console.log(error?.response);
                if (!error?.response) {
                    return {
                        type: 'error',
                        response: {
                            message:
                                'Server error. Please try again after sometime.'
                        }
                    };
                } else {
                    return { type: 'error', response: error?.response };
                }
            });
    };
};

export const ChangeSingleUserPassword = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'POST',
                data,
                url: `${API_PATH}/admin/update-user-password`,
                headers: { Authorization: token }
            };

            const response = (await axios(config as any))?.data;

            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const ChangeAdminPassword = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'POST',
                data,
                url: `${API_PATH}/admin/update-password`,
                headers: { Authorization: token }
            };

            const response = (await axios(config as any))?.data;

            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};
